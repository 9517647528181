<template>
    <team-sheet
        :league="league"
        :team="myTeam"
    />
</template>

<script>
import {Team} from '@/models/Team';
import {League} from '@/models/League';
import TeamSheet from '@/components/leagues/teams/TeamSheet';

export default {
    name: 'LeagueMyTeam',
    components: {TeamSheet},
    props: {
        league: {
            type: League,
            required: true,
        },
    },
    computed: {
        myTeam() {
            if (this.league.current_user_team_user) {
                return new Team(this.league.current_user_team_user.team);
            }

            return undefined;
        },
    },
};
</script>
